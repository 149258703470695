<template>
    <div>
        <title-bar :title="$t('发布广告')" />
        <div class="ad-card-box">
            <van-card :title="siteInfo.name" :desc="siteInfo.summary" :thumb="siteInfo.logoUrl" class="card-item">
                <template #thumb>
                    <van-image fit="fill" :src="siteInfo.logoUrl" />
                </template>
            </van-card>
        </div>

        <van-form @submit="onSubmit" class="form">
            <van-field :label="$t('选择广告')" required v-show="reserveType == 1 && !isEdit">
                <template #input>
                    <van-radio-group v-model="data.specId" direction="horizontal">
                        <van-radio :name="item.id" :key="item.id" v-for="item in siteInfo.specs">{{ item.name }}</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field readonly clickable required :value="cityText" v-show="!isEdit" :label="$t('城市')" :placeholder="$t('点击选择城市')" @click="citySelect.show = true" />
            <city-picker v-model="citySelect.show" :areaId="data.areaId" @confirm="onConfirmCity" @cancel="citySelect.show = false" />
            <van-field v-model="data.street" :label="$t('地区')" :placeholder="$t('请输入地区')" />
            <van-field :label="$t('类型')" required :rules="[{ required: true }]">
                <template #input>
                    <van-radio-group v-model="data.adType" direction="horizontal">
                        <van-radio :name="1">{{ $t('按摩店') }}</van-radio>
                        <van-radio :name="2">{{ $t('住家店') }}</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field v-model="data.adTel" :label="$t('广告电话')" :placeholder="$t('请输入广告电话')" required type="digit" :rules="[{ required: true }]" />
            <van-field v-model="data.privateTel" :label="$t('私人电话')" :placeholder="$t('请输入私人电话')" type="digit" />
            <van-field v-model="data.wechat" :label="$t('微信')" :placeholder="$t('请输入微信号')" />
            <van-field v-model="data.name" :label="$t('姓名')" :placeholder="$t('请输入姓名')" />
            <van-field v-model="data.age" type="digit" :label="$t('年龄')" :placeholder="$t('请输入年龄')" />
            <van-field v-model="data.height" type="digit" :label="$t('身高')" :placeholder="$t('请输入身高（单位cm）')" />
            <van-field v-model="data.title" :label="$t('标题')" :placeholder="$t('请输入广告标题')" />
            <van-field v-model="data.content" :label="$t('内容')" type="textarea" rows="5" autosize :placeholder="$t('请输入广告内容')" />
            <van-field :label="$t('图片来源')">
                <template #input>
                    <van-radio-group v-model="imgSourceType" direction="horizontal">
                        <van-radio :name="1">{{ $t('本地上传') }}</van-radio>
                        <van-radio :name="2">{{ $t('图库选择') }}</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field :label="$t('本地图片')" v-show="imgSourceType == 1">
                <template #input>
                    <uploader v-model="localImgs" :maxCount="5"> </uploader>
                </template>
            </van-field>
            <van-field :label="$t('图库图片')" v-show="imgSourceType == 2">
                <template #input>
                    <div class="van-uploader__wrapper">
                        <van-uploader v-model="libImgs" :show-upload="false" max-count="5"> </van-uploader>
                        <div class="van-uploader__upload" @click="onSelectByLibrary">
                            <van-icon name="add-square" class="van-uploader__upload-icon" />
                        </div>
                    </div>
                </template>
            </van-field>
            <div style="margin: 16px">
                <van-button round block type="info" :loading="loading" native-type="submit">{{ $t('立即发布') }}</van-button>
            </div>
        </van-form>
        <image-lib-picker v-model="imgLibraryShow" :max-count="imageLibMaxCount" @confirm="onConfirmLibImgs" />
    </div>
</template>

<script>
import { Button, Card, Form, Field, RadioGroup, Uploader as VanUploader, Radio, Popup, Icon, Image } from 'vant';
import TitleBar from '../../components/title-bar.vue';
import CityPicker from '../../components/area-picker/city.vue';
import Uploader from '../../components/uploader/index.vue';
import ImageLibPicker from '../../components/imagelib-picker/index.vue';

export default {
    components: {
        TitleBar,
        [Form.name]: Form,
        [Button.name]: Button,
        [Field.name]: Field,
        [Card.name]: Card,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [CityPicker.name]: CityPicker,
        [ImageLibPicker.name]: ImageLibPicker,
        [Uploader.name]: Uploader,
        [VanUploader.name]: VanUploader,
        [Popup.name]: Popup,
        [Icon.name]: Icon,
        [Image.name]: Image,
    },
    computed: {
        cityText() {
            return this.citySelect.cityArea ? `${this.citySelect.cityArea.name}` : undefined;
        },
        imageLibMaxCount() {
            return 5 - this.libImgs.length;
        },
        isEdit() {
            return this.reserveId > 0;
        },
    },
    data() {
        return {
            loading: false,
            siteInfo: {},
            citySelect: {
                show: false,
                cityArea: null,
            },
            imgSourceType: 1,
            localImgs: [],
            libImgs: [],
            imgLibraryShow: false,
            reserveType: 1,
            reserveId: 0,
            data: {
                specId: undefined,
                areaId: 0,
                street: '',
                adType: 1,
                adTel: '',
                privateTel: '',
                wechat: '',
                name: '',
                age: undefined,
                height: undefined,
                title: '',
                content: '',
                imageUrls: [],
            },
        };
    },
    mounted() {
        this.onLoadData();
    },
    methods: {
        async onLoadData() {
            this.loading = true;
            try {
                let query = this.$route.query;
                this.reserveType = query.type;
                this.reserveId = query.id;

                if (this.reserveType == 1) {
                    let res = await this.$userApi.site.getSiteDetail({
                        id: query.typeId,
                    });
                    if (res.status == 200) this.siteInfo = res.data;
                } else if (this.reserveType == 2) {
                    let res = await this.$userApi.site.getSiteComboDetail({
                        id: query.typeId,
                    });
                    if (res.status == 200) this.siteInfo = res.data;
                }
            } finally {
                this.loading = false;
            }
        },
        onConfirmCity(res) {
            this.citySelect.show = false;
            this.citySelect.cityArea = res.city;
            this.data.areaId = res.city.id;
        },
        onSelectByLibrary() {
            this.imgLibraryShow = true;
        },
        onConfirmLibImgs(args) {
            for (let itm of args.items) {
                this.libImgs.push(itm);
            }
            this.imgLibraryShow = false;
        },
        async onSubmit() {
            if (this.reserveType == 1 && !this.data.specId) {
                this.$toast(this.$t('请选择广告'));
                return;
            }
            if (!this.data.areaId || this.data.areaId < 1) {
                this.$toast(this.$t('请选择城市'));
                return;
            }

            if (this.imgSourceType == 1) {
                for (let img of this.localImgs) {
                    if (img.status != 'done') {
                        console.info('当前图片状态', img);
                        this.$toast(this.$t('请等待图片上传完成'));
                        return;
                    }
                }
                this.data.imageUrls = this.localImgs.map((x) => x.url);
            } else {
                this.data.imageUrls = this.libImgs.map((x) => x.url);
            }

            try {
                this.loading = true;

                let res;
                if (!this.isEdit) {
                    res = await this.$userApi.reserve.createReserve({
                        type: this.reserveType,
                        typeId: this.siteInfo.id,
                        info: this.data,
                    });
                } else {
                    res = await this.$userApi.reserve.updateSiteReserve({
                        id: this.reserveId,
                        info: this.data,
                    });
                }
                if (res.status == 200) {
                    await this.$dialog({ message: this.$t('恭喜，发布成功') });
                    this.$router.go(-1);
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.form {
    padding-bottom: 1rem;

    .van-radio {
        margin: 0.1rem 0;
        margin-right: 1rem;
    }
}
</style>