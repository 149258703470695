<template>
  <van-popup v-model="value" @close="onClose" position="bottom" round>
    <van-picker
      ref="picker"
      show-toolbar
      value-key="name"
      :loading="loading"
      :columns="columns"
      :default-index="currIndex"
      @change="onChange"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
  </van-popup>
</template>

<script>
import { Popup, Picker } from "vant";

export default {
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  name: "CityPicker",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    areaId: {
      type: Number,
      default: undefined,
    },
  },
  model: {
    prop: "value",
    event: "visible",
  },
  mounted() {
    this.onLoadData();
  },
  watch: {
    areaId(newVal, oldVal) {
      this.setArea(newVal);
    },
  },
  data() {
    return {
      loading: false,
      currIndex: 0,
      columns: [],
    };
  },
  methods: {
    async onLoadData() {
      this.loading = true;
      let res = await this.$userApi.area.getAllSubAreas({ areaId: 12000000 });
      if (res.status == 200) {
        this.citys = this.getCitys(res.data.data.items);
        this.columns = this.citys.map((x) => x.name);
      }
      this.setArea(this.areaId);
      this.loading = false;
    },
    getCitys(provAreas) {
      let res = [];
      for (let item of provAreas) {
        res.push(...item.subAreas);
      }
      res.sort((x, y) => (x.name > y.name ? 1 : -1));
      return res;
    },
    setArea(areaId) {
      if (!areaId || areaId < 1000000) {
        this.currIndex = 0;
      } else {
        let index = this.citys.findIndex((x) => x.id == areaId);
        this.currIndex = index;
      }
    },
    onChange(picker, val, index) {},
    onConfirm(val, index) {
      this.$emit("confirm", {
        city: this.citys[index],
      });
    },
    onCancel() {
      this.value = false;
    },
    onClose() {
      this.$emit("visible", false);
    },
  },
};
</script>
